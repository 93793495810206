import { render, staticRenderFns } from "./UsuarioProdutos.vue?vue&type=template&id=69cb8f21&scoped=true&"
import script from "./UsuarioProdutos.vue?vue&type=script&lang=js&"
export * from "./UsuarioProdutos.vue?vue&type=script&lang=js&"
import style0 from "./UsuarioProdutos.vue?vue&type=style&index=0&id=69cb8f21&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "69cb8f21",
  null
  
)

export default component.exports