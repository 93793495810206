import axios from "axios";

const url = "http://pnapublicidade.com.br/geane/wp_ranek/wp-json";

const AxiosnijiInstance = axios.create({
  baseURL: url + "/api",
});

AxiosnijiInstance.interceptors.request.use(
  function (config) {
    const token = window.localStorage.token;
    if (token) {
      config.headers.Authorization = token;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);
export const api = {
  get(endpoint) {
    return AxiosnijiInstance.get(endpoint);
  },

  post(endpoint, body) {
    return AxiosnijiInstance.post(endpoint, body);
  },

  put(endpoint, body) {
    return AxiosnijiInstance.put(endpoint, body);
  },
  delete(endpoint) {
    return AxiosnijiInstance.delete(endpoint);
  },
  login(body) {
    return axios.post(url + "/jwt-auth/v1/token", body);
  },
  validateToken() {
    return AxiosnijiInstance.post(url + "/jwt-auth/v1/token/validate");
  },
};

export function getCep(cep) {
  return axios.get(`https://viacep.com.br/ws/${cep}/json/`);
}
