<template>
  <h1>Página não encontrada.</h1>
</template>

<script>
export default {
  name: "PaginaNaoEncontrada",
};
</script>

<style></style>
